import revive_payload_client_yxLmTWb82C from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.3_floating-vue@5.2.2_sass@1.77.2_unocss@0.60.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_t6VBWNPIxb from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.3_floating-vue@5.2.2_sass@1.77.2_unocss@0.60.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1DiYLcGAbZ from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.3_floating-vue@5.2.2_sass@1.77.2_unocss@0.60.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_vdarZi0uZN from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt-site-config@2.2.21/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_8DGAJc5bnw from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.3_floating-vue@5.2.2_sass@1.77.2_unocss@0.60.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_gbgtaBiMDx from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.3_floating-vue@5.2.2_sass@1.77.2_unocss@0.60.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "C:/Users/zreat/Documents/Projects/ludmilaphoto/.nuxt/components.plugin.mjs";
import prefetch_client_Wcq55ZVdGH from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.3_floating-vue@5.2.2_sass@1.77.2_unocss@0.60.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_icons_6aFMR3DOPc from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/vuetify-nuxt-module@0.7.3/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import vuetify_no_client_hints_Ug5Zu804wu from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/vuetify-nuxt-module@0.7.3/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import chunk_reload_client_RzFQ7V6hxx from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.3_floating-vue@5.2.2_sass@1.77.2_unocss@0.60.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vuetify_OpyNCoeiNv from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/vuetify-nuxt-module@0.7.3/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify.mjs";
export default [
  revive_payload_client_yxLmTWb82C,
  unhead_t6VBWNPIxb,
  router_1DiYLcGAbZ,
  _0_siteConfig_vdarZi0uZN,
  payload_client_8DGAJc5bnw,
  check_outdated_build_client_gbgtaBiMDx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Wcq55ZVdGH,
  vuetify_icons_6aFMR3DOPc,
  vuetify_no_client_hints_Ug5Zu804wu,
  chunk_reload_client_RzFQ7V6hxx,
  vuetify_OpyNCoeiNv
]