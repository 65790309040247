import { default as aboutZgpY0CylnwMeta } from "C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/about.vue?macro=true";
import { default as indexcXCJgolI9kMeta } from "C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/index.vue?macro=true";
import { default as servicesQRVxghgzX0Meta } from "C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/services.vue?macro=true";
export default [
  {
    name: aboutZgpY0CylnwMeta?.name ?? "about",
    path: aboutZgpY0CylnwMeta?.path ?? "/about",
    meta: aboutZgpY0CylnwMeta || {},
    alias: aboutZgpY0CylnwMeta?.alias || [],
    redirect: aboutZgpY0CylnwMeta?.redirect,
    component: () => import("C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/about.vue").then(m => m.default || m)
  },
  {
    name: indexcXCJgolI9kMeta?.name ?? "index",
    path: indexcXCJgolI9kMeta?.path ?? "/",
    meta: indexcXCJgolI9kMeta || {},
    alias: indexcXCJgolI9kMeta?.alias || [],
    redirect: indexcXCJgolI9kMeta?.redirect,
    component: () => import("C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/index.vue").then(m => m.default || m)
  },
  {
    name: servicesQRVxghgzX0Meta?.name ?? "services",
    path: servicesQRVxghgzX0Meta?.path ?? "/services",
    meta: servicesQRVxghgzX0Meta || {},
    alias: servicesQRVxghgzX0Meta?.alias || [],
    redirect: servicesQRVxghgzX0Meta?.redirect,
    component: () => import("C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/services.vue").then(m => m.default || m)
  }
]