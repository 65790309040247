<script setup>
  const ico_props = {
    class: "ma-2",
    height: 30,
    width: 30
  }
</script>

<template>
  <v-footer class="d-flex justify-center flex-column">
    <div><v-img src="/LOGO-04.svg" width="100" height="50" alt="Ludmila Zacharová Zakharova logo"/></div>
    <div>Ludmila Zacharová, rodinná fotografka z Prahy</div>
    <div class="d-flex px-5 pt-5">
      <a href="https://www.facebook.com/Zacharova.foto" title="Facebook" target="_blank">
        <img src="/Facebook_Logo_Secondary.png" alt="Facebook" v-bind="ico_props" />
      </a>
      <a href="https://www.instagram.com/ludmila_zacharova_photo" title="Instagram" target="_blank">
        <img src="/Instagram_Glyph_White.svg" alt="Instagram" v-bind="ico_props" />
      </a>
      <a href="https://wa.me/420773026779" title="WhatsApp" target="_blank">
        <img src="/WhatsApp_Glyph_White.svg" alt="WhatsApp" v-bind="ico_props" />
      </a>
    </div>
    <div>
      <a href="tel:+420773026779" title="Phone" target="_blank">
        <v-sheet class="d-flex align-center">
          <img src="/phone-outline.svg" alt="phone" v-bind="ico_props" />
          +420 773026779
        </v-sheet>
      </a>
      <a href="mailto:ludmilaphoto@outlook.com" title="Email" target="_blank">
        <v-sheet class="d-flex align-center">
          <v-img src="/email-outline.svg" alt="email" v-bind="ico_props" />
          ludmilaphoto@outlook.com
        </v-sheet>
      </a>
    </div>
  </v-footer>
</template>
