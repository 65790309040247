

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"theme":{"defaultTheme":"myDarkTheme","themes":{"myDarkTheme":{"dark":true,"colors":{"background":"#3d4045","surface":"#3d4045","on-surface":"#e1e1e1","on-background":"#e1e1e1"}}}},"ssr":true}')
  
  
  
  
  return options
}

